import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { isBrowser } from "./is-browser";

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "ar",
    debug: false, ///ENV.NODE_ENV === "development",
    supportedLngs: ["ar", "en"],
    lng: isBrowser ? window.locale : "ar",

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export const i18next = i18n;

declare global {
  interface Window {
    localization: Record<string, { translation: any }>;
    locale: string;
  }
}
